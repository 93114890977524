import React from "react"

import styles from "../components/link-group.module.css"

function linkGroup(props) {
  return (
    <ul className={styles.linkGroup}>
      {props.indiebound ? (
        <li>
          <a
            className="button button-small button-secondary"
            href={props.indiebound}
            target="_blank"
            rel="noopener noreferrer"
          >
            Indiebound
          </a>
        </li>
      ) : (
        ""
      )}
      {props.amazon ? (
        <li>
          <a
            className="button button-small button-secondary"
            href={props.amazon}
            target="_blank"
            rel="noopener noreferrer"
          >
            Amazon
          </a>
        </li>
      ) : (
        ""
      )}
      {props.barnes_and_noble ? (
        <li>
          <a
            className="button button-small button-secondary"
            href={props.barnes_and_noble}
            target="_blank"
            rel="noopener noreferrer"
          >
            Barnes &amp; Noble
          </a>
        </li>
      ) : (
        ""
      )}
      {props.bookshop ? (
        <li>
          <a
            className="button button-small button-secondary"
            href={props.bookshop}
            target="_blank"
            rel="noopener noreferrer"
          >
            Bookshop
          </a>
        </li>
      ) : (
        ""
      )}
    </ul>
  )
}

export default linkGroup
