import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import BannerHeading from "../components/banner-heading"
import ButtonPrimary from "../components/button-primary"
import Layout from "../components/global/layout"
import LinkGroup from "../components/link-group"
import SectionTitle from "../components/section-title"
import SEO from "../components/seo"

import styles from "./book.module.css"

const Book = ({ data }) => {
  const book = data.booksJson

  return (
    <Layout>
      <SEO
        title={book.title}
        description={
          book.shortdescription ? book.shortdescription : book.description
        }
        image={book.image.childImageSharp.fluid.src}
        pathname={`/book/${book.slug}`}
        article
      />
      <section className={styles.bookSection} style={{ paddingTop: "4rem" }}>
        <h1
          style={{
            fontSize: "42",
            color: "#000022",
            marginBottom: "0",
            textAlign: "center",
            textShadow: "3px 3px 0 rgba(0,0,0,0.30)",
            textTransform: "uppercase",
          }}
        >
          {book.title}
        </h1>
        <p
          style={{
            color: "#000022",
            textAlign: "center",
            fontFamily:
              "'Josefin Sans', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', sans-serif",
            fontSize: "22px",
            fontStyle: "italic",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {book.subtitle}
        </p>

        <div className={`container ${styles.bookColumns}`}>
          <div class={styles.bookColumn}>
            <Image
              className={styles.bookImage}
              fluid={book.image.childImageSharp.fluid}
              alt={book.title}
            />
          </div>

          <div class={styles.bookColumn}>
            <article
              dangerouslySetInnerHTML={{ __html: book.description }}
              className={styles.bookDescription}
            />

            {book.award_image ? (
              <img src={book.award_image.publicURL} alt={book.title} />
            ) : null}

            <div className={styles.ctaGroup}>
              <LinkGroup
                indiebound={book.links.indiebound}
                amazon={book.links.amazon}
                barnes_and_noble={book.links.barnes_and_noble}
                bookshop={book.links.bookshop}
              />
            </div>
            {book.book_trailer != null ? (
              <div className={styles.mediaItem}>
                <a
                  href={book.book_trailer.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {book.book_trailer.alt_title === "" ||
                  !book.book_trailer.alt_title ? (
                    <h3 className={styles.mediaTitle}>
                      WATCH THE BOOK TRAILER
                    </h3>
                  ) : (
                    <h3 className={styles.mediaTitle}>
                      {book.book_trailer.alt_title}
                    </h3>
                  )}
                  <figure
                    style={{ textAlign: "center" }}
                    className={styles.mediaImage}
                  >
                    <img
                      src={book.book_trailer.image.publicURL}
                      alt={book.title}
                    />
                  </figure>
                </a>
              </div>
            ) : null}
          </div>
        </div>
        {book.additional_resources ? (
          <div className={styles.resourcesWrapper}>
            <h3
              style={{
                textAlign: "center",
                position: "absolute",
                top: "-22px",
                left: "0",
                right: "0",
                zIndex: "999",
              }}
            >
              <BannerHeading bannerText="Additional Resources" />
            </h3>
            <div className={`container`}>
              <div className={styles.resources}>
                {book.additional_resources ? (
                  <>
                    {book.additional_resources.map(resource => (
                      <>
                        <div
                          className={styles.mediaItem}
                          style={{ textAlign: "center" }}
                        >
                          <a
                            href={resource.url ? resource.url : resource.link.publicURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h3 className={styles.mediaTitle}>
                              {resource.name}
                            </h3>
                            <figure
                              style={{ textAlign: "center" }}
                              className={styles.mediaImage}
                            >
                              <img
                                src={resource.image.publicURL}
                                alt={resource.title}
                              />
                            </figure>
                          </a>
                        </div>
                      </>
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </section>

      <section>
        <div className="row no-gap">
          {/* AWARDS */}
          {book.awards.length > 0 ? (
            <div
              className="column"
              style={{
                backgroundImage:
                  "linear-gradient(-180deg, #F79256 0%, #BE6B3A 100%)",
                boxShadow: "0 0 15px 0 rgba(0,0,0,0.05)",
                color: "#fff",
                padding: "4rem 7vw 3rem",
                position: "relative",
              }}
            >
              <h3
                style={{
                  textAlign: "center",
                  position: "absolute",
                  top: "-22px",
                  left: "0",
                  right: "0",
                  zIndex: "999",
                }}
              >
                <BannerHeading bannerText="Awards" />
              </h3>
              <ul
                className="awards-list"
                style={{
                  listStyle: "none",
                  fontSize: "0.875rem",
                  margin: "0 0 1rem",
                  textAlign: "center",
                }}
              >
                {book.awards.map(award => (
                  <li className={styles.award}>
                    {award.image != null ? (
                      <figure className={styles.awardIcon}>
                        <img src={award.image.publicURL} alt={award.title} />
                      </figure>
                    ) : (
                      <span className={styles.awardIcon}></span>
                    )}
                    <p className={styles.awardTitle}>{award.name}</p>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {/* REVIEWS */}
          {book.reviews.length > 0 ? (
            <div
              className="column"
              style={{
                backgroundImage:
                  "linear-gradient(-180deg, #C02B2E 45%, #8D2628 100%)",
                boxShadow: "0 0 15px 0 rgba(0,0,0,0.05)",
                color: "#fff",
                padding: "4rem 7vw 3rem",
                position: "relative",
              }}
            >
              <div className="container">
                <h3
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    top: "-22px",
                    left: "0",
                    right: "0",
                    zIndex: "999",
                  }}
                >
                  <BannerHeading bannerText="Reviews" />
                </h3>
                {book.reviews.map(review => (
                  <blockquote className={styles.review}>
                    {review.starred ? (
                      <img
                        src={data.fileName.publicURL}
                        className="review-star"
                        alt="Star icon"
                      />
                    ) : null}
                    <p dangerouslySetInnerHTML={{ __html: review.quote }}></p>
                    <cite
                      style={{
                        display: "block",
                        fontFamily: "--var(displayFont)",
                        fontWeight: "bold",
                      }}
                    >
                      {review.citation}
                    </cite>
                  </blockquote>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </section>

      {/* MEDIA */}
      {book.media.length > 0 ? (
        <section style={{ paddingTop: "4rem" }}>
          <div className="container">
            <SectionTitle title="Media" />
            <div className="row x3">
              {book.media.map(mediaItem => (
                <div className={styles.mediaItem}>
                  <a
                    href={mediaItem.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {mediaItem.image != null ? (
                      <figure className={styles.mediaImage}>
                        <img
                          src={mediaItem.image.publicURL}
                          alt={mediaItem.title}
                        />
                      </figure>
                    ) : null}
                    <p
                      className={styles.mediaTitle}
                      dangerouslySetInnerHTML={{ __html: mediaItem.name }}
                    ></p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($slug: String!) {
    fileName: file(relativePath: { eq: "images/reviews/review-star.svg" }) {
      publicURL
    }
    booksJson(slug: { eq: $slug }) {
      title
      subtitle
      slug
      description
      shortdescription
      award_image {
        publicURL
      }
      release_date
      image {
        childImageSharp {
          fluid {
            src
            ...GatsbyImageSharpFluid
          }
        }
      }
      discussion_guide {
        publicURL
      }
      additional_resources {
        name
        image {
          publicURL
        }
        link {
          publicURL
        }
        url
      }
      discussion_guide_url
      book_trailer {
        image {
          publicURL
        }
        link
        alt_title
      }
      links {
        indiebound
        amazon
        barnes_and_noble
        bookshop
      }
      awards {
        name
        image {
          publicURL
        }
      }
      reviews {
        quote
        citation
        starred
      }
      media {
        name
        image {
          publicURL
        }
        link
      }
    }
  }
`

export default Book
