import PropTypes from "prop-types"
import React from "react"

import styles from "./banner-heading.module.css"

function BannerHeading({ bannerText }) {
  return <span className={styles.heading}>{bannerText}</span>
}

BannerHeading.propTypes = {
  bannerText: PropTypes.string.isRequired,
}

export default BannerHeading
