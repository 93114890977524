import PropTypes from "prop-types"
import React from "react"

function SectionTitle({ id, titleClass, title }) {
  return (
    <h2
      id={`${id}`}
      className={`${titleClass}`}
      style={{
        fontSize: "4rem",
        textAlign: "center",
        textShadow: "3px 3px 0 rgba(0,0,0,0.30)",
      }}
    >
      {title}
    </h2>
  )
}

SectionTitle.propTypes = {
  titleClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default SectionTitle
