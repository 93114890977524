import PropTypes from "prop-types"
import React from "react"

function ButtonPrimary({
  buttonLink,
  buttonText,
  buttonType,
  buttonSize,
  target,
}) {
  target = target ? "_blank" : "_self"

  return (
    <a
      className={`button button-${buttonType} button-${buttonSize}`}
      href={buttonLink}
      target={target}
    >
      {buttonText}
    </a>
  )
}

ButtonPrimary.propTypes = {
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonType: PropTypes.string.isRequired,
  buttonSize: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
}

export default ButtonPrimary
